import { Base } from '.'

export class ConfigurarionOrganization extends Base {
  id: number
  subdomain: string
  name: string
  logo: string
  firebaseConfig: Record<string, unknown>
  anonymousAccessUser: boolean
}

export class ConfigurarionFeatures extends Base {
  rewards: boolean
  packages: boolean
  suppressProfessional: boolean
  randomProfessionalScheduling: boolean
  forwardDays: number
  groupByCountry: boolean
  lateLogin: boolean
  mandatoryLogin: boolean
  multipleSalons: boolean
  multipleRegions: boolean
  webschedule: boolean
}

export class ConfigurarionColors extends Base {
  darkMode: boolean
  primary: string
  secondary: string
}

export type StringsRecordType = string | any; // eslint-disable-line @typescript-eslint/no-explicit-any
export type StringsType = Record<string, StringsRecordType>;

export class Configuration extends Base {
  organization: ConfigurarionOrganization
  features: ConfigurarionFeatures
  colors: ConfigurarionColors
  strings: StringsType

  constructor (organization: ConfigurarionOrganization, features: ConfigurarionFeatures,
    colors: ConfigurarionColors, strings: StringsType) {
    super()
    this.organization = organization
    this.features = features
    this.colors = colors
    this.strings = strings
  }

  public static fromJson (json: Record<string, any>) : any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return new Configuration(
      ConfigurarionOrganization.fromJson(json.organization) as ConfigurarionOrganization,
      ConfigurarionFeatures.fromJson(json.features) as ConfigurarionFeatures,
      ConfigurarionColors.fromJson(json.colors) as ConfigurarionColors,
      json.strings as StringsType)
  }
}
