


























import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'
import firebase from '@/plugins/firebase'
import { AxiosError } from 'axios'

export type IFormLogin = Vue & { validate: () => boolean, reset: () => boolean }

@Component({
  computed: {
    ...mapState(['showLogin']),
    ...mapGetters(['translate', 'translateFirebaseError'])
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading'])
  }
})
export default class LoginSignin extends Vue {
  email = ''
  password = ''
  passwordRules: Record<symbol, unknown>[] = []
  emailRules: Record<symbol, unknown>[] = []
  showLogin!: boolean
  errorMessage: string | null = null

  currentState!: string
  showLoading!: () => void
  hideLoading!: () => void
  translate!: (key: string) => string
  translateFirebaseError!: (error: AxiosError) => string

  get showErrorMessage () : boolean {
    return this.errorMessage !== null
  }

  get showModal () : boolean {
    return this.showLogin
  }

  get formLogin () : IFormLogin {
    return this.$refs.formLogin as IFormLogin
  }

  submit () : void {
    this.errorMessage = null
    this.emailRules = [
      (v: string) => !!v || this.translate('input.errors.requiredEmail'),
      (v: string) => /.+@.+\..+/.test(v.trim()) || this.translate('input.errors.invalidEmail')
    ]
    this.passwordRules = [
      (v: string) => !!v || this.translate('input.errors.requiredPassword'),
      (v: string) => (v && v.length >= 5) || this.translate('input.errors.invalidPassswordLength')
    ]
    const self = this // eslint-disable-line @typescript-eslint/no-this-alias
    setTimeout(function () {
      if (self.formLogin.validate()) {
        self.showLoading()
        firebase.auth().signInWithEmailAndPassword(self.email.trim(), self.password)
          .then(() => setTimeout(function () { self.formLogin.reset() }, 2000))
          .catch(error => { self.errorMessage = self.translateFirebaseError(error) })
          .finally(() => self.hideLoading())
      }
    })
  }
}
