























import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'
import firebase from '@/plugins/firebase'
import { AxiosError } from 'axios'

export type IFormForgot = Vue & { validate: () => boolean }

@Component({
  computed: {
    ...mapState(['showLogin']),
    ...mapGetters(['translate', 'translateFirebaseError'])
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setSucessAlert', 'setErrorAlert'])
  }
})
export default class LoginSignin extends Vue {
  email = ''
  emailRules: Record<symbol, unknown>[] = []
  errorMessage: string | null = null

  currentState!: string
  setSucessAlert!: (arg1: string) => void
  setErrorAlert!: (arg1: string) => void
  showLoading!: () => void
  hideLoading!: () => void
  translate!: (key: string) => string
  translateFirebaseError!: (error: AxiosError) => string

  get showErrorMessage () : boolean {
    return this.errorMessage !== null
  }

  get formForgot () : IFormForgot {
    return this.$refs.formForgot as IFormForgot
  }

  back () : void {
    this.$emit('update:currentState', 'signin')
  }

  submit () : void {
    this.errorMessage = null
    this.emailRules = [
      (v: string) => !!v || this.translate('input.errors.requiredEmail'),
      (v: string) => /.+@.+\..+/.test(v.trim()) || this.translate('input.errors.invalidEmail')
    ]
    const self = this // eslint-disable-line @typescript-eslint/no-this-alias
    setTimeout(function () {
      if (self.formForgot.validate()) {
        self.showLoading()
        firebase.auth().sendPasswordResetEmail(self.email.trim())
          .then(() => {
            self.setSucessAlert(`${self.translate('screens.login.forgotSuccess')} - ${self.email.trim()}`)
            self.back()
          })
          .catch(error => { self.errorMessage = self.translateFirebaseError(error) })
          .finally(() => self.hideLoading())
      }
    })
  }
}
