























import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'
import firebase from '@/plugins/firebase'
import { AxiosError } from 'axios'

export type IFormAnonymousAccess = Vue & { validate: () => boolean }

@Component({
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setAnonymousData', 'setFinishAnonymousSchedule']),
    ...mapState(['finishAnonymousSchedule'])
  },
  computed: {
    ...mapGetters(['translate', 'translateFirebaseError'])
  }
})
export default class AnonymousLogin extends Vue {
  name = ''
  phone = ''

  phoneRules: Record<symbol, unknown>[] = []
  nameRules: Record<symbol, unknown>[] = []
  errorMessage: string | null = null
  translate!: (key: string) => string
  translateFirebaseError!: (error: AxiosError) => string
  setAnonymousData!: (arg1: string) => void
  setFinishAnonymousSchedule!: (arg1: boolean) => void
  showLoading!: () => void
  hideLoading!: () => void

  get showErrorMessage () : boolean {
    return this.errorMessage !== null
  }

  get formAccessAnonymous () : IFormAnonymousAccess {
    return this.$refs.formAccessAnonymous as IFormAnonymousAccess
  }

  submit () : void {
    this.nameRules = [
      (v: string):string|boolean => (v && v.length >= 3) || this.translate('input.errors.requiredName') // ,
    ]
    this.phoneRules = [
      (v: string):string|boolean => !!v || this.translate('input.errors.requiredPhone'),
      (v: string):string|boolean => (v && v.length >= 14) || this.translate('input.errors.invalidPhone')
    ]
    const self = this // eslint-disable-line @typescript-eslint/no-this-alias
    setTimeout(function () {
      if (self.formAccessAnonymous.validate()) {
        self.showLoading()
        self.setAnonymousData(new URLSearchParams({ name: self.name, phone: self.phone }).toString())
        setTimeout(function () {
          firebase.auth().signInAnonymously()
            .then(() => {
              self.setFinishAnonymousSchedule(true)
            })
            .catch(error => {
              self.errorMessage = self.translateFirebaseError(error)
            })
            .finally(() => self.hideLoading())
        })
      }
    })
  }
}
