import Vue from 'vue'
import './plugins/axios'
import './plugins/vue-the-mask'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './plugins/firebase'

import ApiService from './api'
import { setupTracker } from '@/lib/rwg'
import './assets/scss/app.scss'
import { Configuration, StringsType } from './models'
import { AxiosError } from 'axios'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

  created: function () {
    store.dispatch('setReady', false)
    store.dispatch('showLoading')
    this.setupParams()
    this.setupRWGTracker()
    ApiService.getSettings().then((config: Configuration) => {
      store.dispatch('hideLoading')
      if (config.features.webschedule) {
        this.setupConfig(config)
        this.setupTheme(config)
        this.setupFirebase(config.organization.anonymousAccessUser, config.organization.firebaseConfig, config.strings)
      } else {
        window.location.href = 'https://bemp.com.br'
      }
    })
  },

  methods: {
    setupParams: function () {
      store.dispatch('setEmbedded', this.$route.query.embedded !== undefined)
      store.dispatch('setTransparentBackground', this.$route.query.transparent !== undefined)
    },

    setupRWGTracker: function () {
      const { rwg_environment: environment, rwg_token: token } = this.$route.query
      const { salon } = this.$route.params

      setupTracker(environment, token, salon)
    },

    setupConfig: (config: Configuration) => {
      store.dispatch('setConfig', config)
      document.title = `${config.organization.name} - ${config.strings.title}`
    },

    setupTheme: function (config: Configuration) {
      this.$vuetify.theme.dark = config.colors.darkMode
      this.$vuetify.theme.themes.light.primary = config.colors.primary
      this.$vuetify.theme.themes.light.secondary = config.colors.secondary
      this.$vuetify.theme.themes.dark.primary = config.colors.primary
      this.$vuetify.theme.themes.dark.secondary = config.colors.secondary
    },

    setupFirebase: function (anonymousAccessUser: boolean, firebaseConfig: Record<string, unknown>, strings: StringsType) {
      firebase.initializeApp(firebaseConfig)

      firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
        if (anonymousAccessUser) {
          if (this.$route.path.includes('/schedules')) this.$router.push('/')
          firebase.auth().signOut().then(() => {
            store.dispatch('setCustomer', null)
            if (this.$route.path.includes('/schedules')) this.$router.push('/')
          })
          store.dispatch('setCustomer', user)
        } else {
          if (user) {
            user.getIdToken(true)
              .then((token: string) => ApiService.registrationCheck(token))
              .then(() => store.dispatch('setCustomer', user))
              .catch((e: AxiosError) => {
                const message = e.response?.data?.messages?.join(', ') || strings.errors.firebase['auth/user-not-found']
                store.dispatch('setErrorAlert', message)
                firebase.auth().signOut()
              })
          } else {
            store.dispatch('setCustomer', null)
          }
        }
      })
    }
  }
}).$mount('#app')
