



























import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

import ApiService from '../api'
import ScheduleSalon from './schedule/salon.vue'
import ScheduleService from './schedule/service.vue'
import ScheduleProfessional from './schedule/professional.vue'
import ScheduleSlot from './schedule/slot.vue'
import SchedulesTotal from './schedule/total.vue'
import SocialLinks from './schedule/socialLinks.vue'

import { Salon, Service, Professional, Slot, Schedule, scheduleMessage, Region, Configuration } from '../models'
import { Route } from 'vue-router'
import { AxiosError } from 'axios'
import moment from '@/plugins/moment'
import { trackConversion, trackingActive } from '@/lib/rwg'

@Component({
  components: { ScheduleSalon, ScheduleService, ScheduleProfessional, ScheduleSlot, SchedulesTotal, SocialLinks },

  computed: {
    ...mapState(['config', 'firebaseToken']),
    ...mapGetters(['logged', 'translate', 'isAnonymousAccess', 'finishAnonymousSchedule'])
  },

  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setSucessAlert', 'setErrorAlert', 'checkNeedLogin', 'setFinishAnonymousSchedule'])
  }
})
export default class ScheduleComponent extends Vue {
  region: Region = new Region()
  salon: Salon = new Salon()
  service: Service = new Service()
  professional: Professional = new Professional()
  date = ''
  slot: Slot = new Slot()
  id: number | null = null

  useUrlParams = true

  config!: Configuration
  firebaseToken!: string
  finishAnonymousSchedule!: boolean
  logged!: boolean
  isAnonymousAccess!: boolean
  translate!: (key: string) => string
  setSucessAlert!: (arg1:string) => void
  setFinishAnonymousSchedule!: (arg1: boolean) => void
  setErrorAlert!: (arg1:string) => void
  checkNeedLogin!: (arg1:boolean) => void
  showLoading!: () => void
  hideLoading!: () => void

  @Prop() schedule: Schedule

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (to: Route, from: Route) : void {
    if (from) this.$router.go(0)
  }

  beforeMount () : void {
    if (this.schedule) {
      this.id = this.schedule.id
      this.region = this.schedule.region
      this.salon = this.schedule.salon
      this.service = this.schedule.service
      this.professional = this.schedule.professional
      this.date = moment(this.schedule.date).format('YYYY-MM-DD')
    }
  }

  get scheduleRegion () : Region {
    return (this.schedule && this.schedule.region) ? this.schedule.region : new Region()
  }

  get urlSalonId () : string | null {
    var salon : string | null = this.$route.params.salon
    salon ??= (this.$route.query.salon ? this.$route.query.salon.toString() : null)

    return salon
  }

  get urlProfessionalId () : string | null {
    var professional : string | null = this.$route.params.professional
    professional ??= (this.$route.query.professional ? this.$route.query.professional.toString() : null)

    return professional
  }

  get urlSalon () : string | null {
    const salon : string | null = this.urlSalonId

    if (!this.useUrlParams || salon === null) return null
    if (!this.config || !this.config.features.multipleSalons) return null

    return salon
  }

  get urlProfessional () : string | null {
    const salon : string | null = this.urlSalonId
    const professional : string | null = this.urlProfessionalId

    if (!this.useUrlParams || (salon === null && professional === null)) return null
    if (!this.config) return null

    return this.config.features.multipleSalons ? professional : salon
  }

  get urlService () : string | null {
    return this.$route.query.service ? this.$route.query.service.toString() : null
  }

  get getSchedule () : Schedule {
    return Schedule.fromJson(this.$data)
  }

  get salonChooser () : ScheduleSalon {
    return this.$refs.salonChooser as ScheduleSalon
  }

  get showProfessional () : boolean {
    return this.service.id !== undefined && !this.config.features.suppressProfessional
  }

  get noProfessionalPreference () : boolean {
    return this.config.features.randomProfessionalScheduling
  }

  get showSlot () : boolean {
    return this.service.id !== undefined && (this.professional.id !== undefined || this.config.features.suppressProfessional || this.professional.id === -1)
  }

  get btnSave () : string {
    if (this.logged && this.finishAnonymousSchedule) {
      return this.translate('screens.schedule.save.loading')
    }
    if (this.logged) {
      return this.translate('buttons.saveSchedule')
    } else if (this.isAnonymousAccess) {
      return this.translate('menu.schedule')
    } else {
      return this.translate('buttons.signinToContinue')
    }
  }

  canSave () : boolean {
    return this.salon.id !== undefined &&
      this.service.id !== undefined &&
      this.date !== null &&
      this.slot.start !== undefined
  }

  @Watch('finishAnonymousSchedule', { immediate: true, deep: true })
  onFinishWhenAnonymousLogged () : void {
    if (this.finishAnonymousSchedule && this.firebaseToken) {
      this.showLoading()
      const self = this // eslint-disable-line @typescript-eslint/no-this-alias
      setTimeout(function () {
        self.saveSchedule()
      })
    }
  }

  save () : void {
    if (this.logged) {
      this.saveSchedule()
    } else {
      this.checkNeedLogin(true)
    }
  }

  saveSchedule () : void {
    const origin = trackingActive() ? 'google' : 'website'

    ApiService.saveSchedule(this.getSchedule, origin)
      .then(() => {
        trackConversion(this.getSchedule.salon.id)
        if (this.isAnonymousAccess) {
          this.setSucessAlert(this.translate('screens.schedule.save.success'))
          this.reset('all')
        } else {
          this.setSucessAlert(this.translate('screens.schedule.save.success'))
          this.reset('all')
          this.$router.push('/schedules')
        }
      })
      .catch((e: AxiosError) => this.setErrorAlert(scheduleMessage(e, this.translate('errors.invalid_token'))))
      .finally(() => {
        if (this.isAnonymousAccess) {
          this.setFinishAnonymousSchedule(false)
          this.hideLoading()
        }
      })
  }

  reset (option = 'all') : void {
    if (['all'].includes(option)) {
      this.region = new Region()
      this.salonChooser.loadRegions()
    }
    if (['all', 'salon'].includes(option)) this.salon = new Salon()
    if (['all', 'salon', 'service'].includes(option)) this.service = new Service()
    if (['all', 'salon', 'service', 'professional'].includes(option)) this.professional = new Professional()
    if (['all', 'salon', 'service', 'professional', 'slot'].includes(option)) this.date = ''
    if (['all', 'salon', 'service', 'professional', 'slot'].includes(option)) this.slot = new Slot()
    if (['all', 'salon', 'professional'].includes(option)) this.useUrlParams = false
  }
}
