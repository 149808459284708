



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import moment from '../../plugins/moment'
import { Schedule, Slot, ISelect, Configuration } from '../../models'
import ScheduleCard from '../card/card.vue'
import ApiService from '../../api'

@Component({
  components: { ScheduleCard },
  computed: {
    ...mapState(['config']),
    ...mapGetters(['translate'])
  }
})
export default class Scheduleslot extends Vue {
  @Prop() schedule: Schedule | undefined
  config!: Configuration

  dates: moment.Moment[] = []
  date!: string
  slots: Slot[] = []
  loading = true
  translate!: (key: string) => string

  beforeMount () : void {
    if (this.schedule && this.schedule.date !== '') this.date = this.schedule.date
    this.loading = true
    this.dates = this.loadDates()
    this.loadDate()
  }

  get dateItems () : ISelect[] {
    const dates: ISelect[] = this.dates.map((d: moment.Moment) => ({
      text: d.format('DD [de] MMMM [de] YYYY | dddd'),
      value: d.format('YYYY-MM-DD')
    }))
    return dates
  }

  loadDates () : Array<moment.Moment> {
    var dates = []
    var currDate = moment().subtract(1, 'day')
    var lastDate = currDate.clone().add(this.config.features.forwardDays + 1, 'days').endOf('day')

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone())
    }

    return dates as Array<moment.Moment>
  }

  loadDate () : void {
    this.loading = true
    if (this.date === undefined) this.date = this.dates[0].format('YYYY-MM-DD')
    this.loadSlots()
  }

  loadSlots () : void {
    this.loading = true
    this.slots = []
    this.$emit('update:date', this.date)
    this.schedule && ApiService.getScheduleSlots(this.schedule.salon, this.schedule.service, this.schedule.professional, this.date)
      .then((s: Slot[]) => {
        this.slots = s
        this.loading = false
      })
  }

  onChange (value: string) : void {
    this.date = value
    this.loadSlots()
  }

  get notfoundSlots () : boolean {
    return (!this.loading && this.slots.length === 0)
  }
}
