



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Schedule, Salon, Region, Configuration } from '../../models'
import ScheduleCard from '../card/card.vue'
import ApiService from '../../api'
import { mapState, mapGetters } from 'vuex'

@Component({
  components: { ScheduleCard },

  computed: {
    ...mapState(['config']),
    ...mapGetters(['translate'])
  }
})
export default class ScheduleSalon extends Vue {
  @Prop() schedule: Schedule | undefined
  @Prop() urlSalon: string | null
  @Prop() sheduleRegion: Region | undefined

  salons: Array<Salon> = []
  regions: Region[] = []
  loadingRegion = true
  loading = false
  region: Region | undefined = new Region()

  config!: Configuration
  translate!: (key: string) => string

  beforeMount () : void {
    if (this.sheduleRegion && this.sheduleRegion.id === undefined) {
      this.loadRegions()
    } else {
      this.loadingRegion = false
      this.loading = false
      this.region = this.sheduleRegion
    }
  }

  get showRegion () : boolean {
    return this.config.features.multipleRegions
  }

  get showSalon () : boolean {
    return this.config.features.multipleSalons && (
      (this.region !== undefined && this.region.id !== undefined) || !this.config.features.multipleRegions
    )
  }

  updateParent (value: Salon | null) : void {
    this.$emit('update:salon', value)
  }

  loadRegions () : void {
    this.loadingRegion = true
    this.regions = []
    this.region = new Region()
    ApiService.getSalons().then((s: Array<Salon>) => {
      this.salons = s
      this.regions = this.mapRegions()
      if (this.regions.length === 1) this.loadSalons(this.regions[0])
      if (this.urlSalon !== null) this.fetchUrlSalon()
      this.loadingRegion = false
    })
  }

  loadSalons (region: Region) : void {
    this.loading = true
    if (this.config.features.multipleRegions) {
      this.region = region
      this.salons = this.salons.filter((s: Salon) => s.remoteGrouping === region.id)
    }
    if (this.salons.length === 1) this.updateParent(this.salons[0])
    this.loading = false
  }

  fetchUrlSalon () : void {
    const salon = this.salons.find(s => s.slug === this.urlSalon)
    if (salon) {
      this.loading = false
      this.loadingRegion = false
      this.region = this.regions.find(r => r.id === salon.remoteGrouping)
      this.updateParent(salon)
    }
  }

  mapRegions () : Region[] {
    const grouped = this.groupBy(this.salons, (x) => x.remoteGrouping)
    return Object.keys(grouped)
      .map((v: string) => new Region(grouped[v][0]))
      .sort((a: Region, b: Region) => (a && a.name && b) ? a.name.localeCompare(b.name as string) : 0)
  }

  groupBy = <T, K extends keyof any> (list: T[], getKey: (item: T) => K) : Record<K, T[]> => // eslint-disable-line @typescript-eslint/no-explicit-any
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem)
      if (!previous[group]) previous[group] = []
      previous[group].push(currentItem)
      return previous
    }, {} as Record<K, T[]>)
}
