






















import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import firebase from '@/plugins/firebase'

@Component({
  computed: {
    ...mapGetters(['logged', 'isAnonymousAccess', 'translate'])
  },
  methods: {
    ...mapActions(['checkNeedLogin'])
  }
})
export default class MenuTabComponent extends Vue {
  logged!: boolean
  isAnonymousAccess!: boolean
  translate!: (key: string) => string
  checkNeedLogin!: (arg1:boolean) => void
  login () : void {
    this.checkNeedLogin(true)
  }

  logoff () : void {
    firebase.auth().signOut().then(() => {
      this.checkNeedLogin(false)
      if (this.$route.path !== '/') this.$router.push('/')
    })
  }
}
